/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import 'theme/styles';

h1,
.h1 {
  margin-top: 20px;
  font-size: 30px;
}

h2,
.h2 {
  margin-top: 18px;
  font-size: 22px;
}

h3,
.h3 {
  font-size: 20px;
}

h4,
.h4 {
  font-size: 18px;
}

label:not(.form-check-label) {
  color: var(--bs-gray-600);
}

a {
  color: var(--bs-link-color) !important;
  text-decoration: none;
  cursor: pointer;

  &:visited {
    color: var(--bs-link-color) !important;
  }

  &:active {
    color: var(--bs-link-color) !important;
  }

  &:focus-visible {
    outline: none;
  }

  &:hover {
    color: var(--bs-link-hover-color) !important;
  }
}

legend {
  font-size: 20px;
  font-weight: 500;
  color: var(--bs-secondary);
}

.content {
  background: #fff;
  padding: 30px 16px 0;
}

.card-footer {
  background-color: transparent;
}

.bg-sidebar {
  background: linear-gradient(
    to top left,
    var(--bs-gray-100),
    var(--bs-gray-500)
  );
}

.bg-sidebar-alt {
  background: linear-gradient(
    to top right,
    var(--bs-gray-100),
    var(--bs-gray-500)
  );
}

.sidebar {
  padding: 0 24px 48px;
  position: sticky;
  height: 100vh;

  h1 {
    text-align: center;
    font-weight: 500;
    color: var(--bs-primary);
  }

  img.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.font {
  &-small {
    font-size: small;
  }

  &-weight-normal {
    font-weight: 300;
  }
}

.color {
  &-medium {
    color: var(--bs-gray-500);
  }

  &-dark {
    color: var(--bs-dark);
  }

  &-primary {
    color: var(--bs-primary);
  }

  &-error {
    color: var(--bs-danger);
  }
}

hr.hr-style {
  background-color: rgba(255, 255, 255, 0.3);
}

input.ng-invalid.ng-touched:not(.ng-pending):not(.no-border),
textarea.ng-invalid.ng-touched:not(.ng-pending) {
  border: 1px solid var(--bs-danger);
  background-color: rgba(220, 53, 69, 0.1);

  &:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
}

select.ng-invalid.ng-touched:not(.ng-pending) {
  border: 1px solid var(--bs-danger);
  background-color: rgba(220, 53, 69, 0.1);

  &:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
}

ngb-datepicker.ng-invalid.ng-touched:not(.ng-pending) {
  border: 1px solid var(--bs-danger);
  background-color: rgba(220, 53, 69, 0.1);

  &:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
}

ngb-datepicker {
  display: block !important;

  .ngb-dp-months {
    display: block !important;
  }

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 100% !important;
  }
}

/*
  @see https://getbootstrap.com/docs/5.2/layout/breakpoints/
*/
@media (max-width: 576px) {
  button:not(.input-group-btn):not(.btn-close):not(.no-expand) {
    width: 100%;
  }

  .content {
    padding-top: 16px;
  }
}

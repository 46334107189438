:host {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --bs-primary: #16216a;
  --bs-primary-rgb: 22, 33, 106;
  --bs-primary-hover: #b9122b;
  --bs-primary-disabled: #2e3669;
  --bs-secondary: #707070;
  --bs-secondary-rgb: 112, 112, 112;
  --bs-tertiary: #b9122b;
  --bs-tertiary-rgb: 185, 18, 43;
  --bs-tertiary-hover: #16216a;
  --bs-tertiary-disabled: #9b424f;
  --bs-link-color: #b9122b;
  --bs-link-hover-color: #00247c;
  --bs-info: #707070;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-hover);
  --bs-btn-hover-border-color: var(--bs-primary-hover);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary-disabled);
  --bs-btn-disabled-border-color: var(--bs-primary-disabled);
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-gradient: none;
}

.btn-tertiary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-tertiary);
  --bs-btn-border-color: var(--bs-tertiary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-tertiary-hover);
  --bs-btn-hover-border-color: var(--bs-tertiary-hover);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-tertiary);
  --bs-btn-active-border-color: var(--bs-tertiary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-tertiary-disabled);
  --bs-btn-disabled-border-color: var(--bs-tertiary-disabled);
}

.btn-outline-tertiary {
  --bs-btn-color: var(--bs-tertiary);
  --bs-btn-border-color: var(--bs-tertiary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-tertiary);
  --bs-btn-hover-border-color: var(--bs-tertiary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-tertiary);
  --bs-btn-active-border-color: var(--bs-tertiary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-tertiary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-tertiary);
  --bs-gradient: none;
}

.btn-link {
  text-decoration: none;
}

.progress {
  --bs-progress-height: .25rem;
  --bs-progress-border-radius: 0;
}

.alert-light {
  --bs-alert-bg: var(--bs-gray-100);
  --bs-alert-border-color: var(--bs-gray-100);
}
